<template>
  <div class="box" v-loading='loading'>
    <div class="banerText">
      <div @click="prev" class="homeText">{{i18n.User.home}}</div>
      <div class="icon">></div>
      <div class="userInfoText">{{i18n.User.personal}}</div>
    </div>
    <div class="content">
      <el-card class="box-card">
        <div class="header">{{i18n.User.settings}}</div>
        <div class="box">
          <el-row>
            <el-col :span="8">
              <div class="left">
                <el-image
                        class="circleImg"
                        fit="cover"
                        style="width: 100px; height: 100px"
                        :src="userInfo.headimgurl"
                ></el-image>
                <el-upload
                        ref="upload"
                        :http-request='uploadRequest'
                >
                  <el-button round size="mini" type="primary">{{i18n.User.change}}</el-button>
                </el-upload>
              </div>
            </el-col>
            <br>
            <br>
            <el-col :span="16">
              <div class="right">
                <el-row>
                  <el-col :span="8">{{i18n.User.Nickname}}</el-col>
                  <el-col :span="8">{{userInfo.name}}
                    <svg v-if="userInfo&&userInfo.vip&&userInfo.vip.code" t="1623060154872" class="icon"
                         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9839"
                         height="44">
                      <path d="M878.3 692.9H147.7c-27.4 0-49.7-22.2-49.7-49.7V370.3c0-27.4 22.2-49.7 49.7-49.7h730.6c27.4 0 49.7 22.2 49.7 49.7v272.9c0 27.5-22.2 49.7-49.7 49.7z"
                            fill="#3F434E" p-id="9840"></path>
                      <path d="M401.5 476.3c0-11.8-9.5-21.3-21.3-21.3s-21.3 9.5-21.3 21.3c0 4.3 1.6 8 3.7 11.4L312 526l-26.1-74c6.5-3.6 11.1-10.3 11.1-18.3 0-11.8-9.5-21.3-21.3-21.3s-21.3 9.5-21.3 21.3c0 8 4.6 14.7 11.1 18.3l-26.2 74-50.6-38.3c2.2-3.3 3.8-7.1 3.8-11.4 0-11.8-9.5-21.3-21.3-21.3s-21.3 9.5-21.3 21.3c0 11.4 9 20.5 20.3 21.1l42.6 103.8h125.9l42.6-103.8c11.1-0.6 20.2-9.7 20.2-21.1zM601.7 408.5c-4.8-2-10.2 0.5-12.1 5.3L525.2 577l-63.7-163.1c-1.9-4.8-7.3-7.2-12.1-5.3-4.8 1.9-7.2 7.3-5.3 12.1L516.5 606c1.4 3.6 4.8 5.9 8.7 5.9 3.8 0 7.3-2.3 8.7-5.9l73.2-185.3c1.7-4.9-0.6-10.3-5.4-12.2zM652 407.8c-5.2 0-9.3 4.2-9.3 9.3v185.3c0 5.2 4.2 9.3 9.3 9.3s9.3-4.2 9.3-9.3V417.2c0-5.2-4.2-9.4-9.3-9.4zM789.2 411.2h-75.3c-0.2 0-0.4 0.1-0.6 0.1-0.2 0-0.4-0.1-0.6-0.1-5.2 0-9.3 4.2-9.3 9.3v181.9c0 5.2 4.2 9.3 9.3 9.3s9.3-4.2 9.3-9.3v-71.3h67.1c33.1 0 60-26.9 60-60s-26.9-59.9-59.9-59.9z m0 101.2H722v-82.6h67.1c22.8 0 41.3 18.5 41.3 41.3 0 22.8-18.5 41.3-41.2 41.3z"
                            fill="#FFD791" p-id="9841"></path>
                    </svg>
                  </el-col>
                  <el-col :span="8">
                    <el-button
                            type="text"
                            class="btn"
                            @click="updateData('nickName')"
                    >{{i18n.User.Modify}}
                    </el-button
                    >
                  </el-col
                  >
                </el-row>
                <el-row>
                  <el-col :span="8">UID</el-col>
                  <el-col :span="8">{{userInfo.student_no}}</el-col>
                  <el-col :span="8">
                    <el-button
                            type="text"
                            class="btn"
                            @click="copyStudentNo">
                      {{$t('User.copy')}}
                    </el-button>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <div>
                      {{i18n.User.phone}}
                      <el-button type="info" size="mini">{{i18n.User.Verified}}</el-button>
                    </div>
                  </el-col>
                  <el-col :span="8">{{userInfo.phone}}</el-col>
                  <el-col :span="8">
                    <el-button type="text" class="btn"
                               @click="updatePhoneNumber"
                    >{{i18n.User.Replace}}
                    </el-button
                    >
                  </el-col
                  >
                </el-row>
                <!-- <el-row>
                  <el-col :span="8">
                    <div>
                      绑定邮箱
                      <el-button type="danger" size="mini">待验证</el-button>
                    </div>
                  </el-col>
                  <el-col :span="8">2719117297@qq.com</el-col>
                  <el-col :span="8">
                    <el-button v-if="false" type="text" class="btn"
                     @click="updateData('email')"
                      >验证邮箱</el-button
                    >
                     <el-button v-if="false" type="text" class="btn"
                     @click="updateData('bindEmail')"
                      >绑定邮箱</el-button
                    >
                     <el-button v-if="true" type="text" class="btn"
                     @click="updateData('relieveEmail')"
                      >解绑邮箱</el-button
                    >
                    </el-col
                  >
                </el-row> -->
                <el-row>
                  <el-col :span="8">
                    <div>{{i18n.User.password}}</div>
                  </el-col>
                  <el-col :span="8">**********</el-col>
                  <el-col :span="8">
                    <el-button type="text" class="btn"
                               @click="updatePsw"
                    >{{i18n.User.changePassword}}
                    </el-button
                    >
                  </el-col
                  >
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <div>{{i18n.User.time}}</div>
                  </el-col>
                  <el-col :span="8">{{userInfo.country_zone_name}}</el-col>
                  <el-col :span="8">
                    <el-button type="text" class="btn"
                               @click="updateDate"
                    >{{i18n.User.setTime}}
                    </el-button
                    >
                  </el-col
                  >
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>

    <el-dialog
            :title="title"
            :visible.sync="updateDiaLogType"
            center
    >
      <div style="text-align: center;">
        <input v-model="value" :placeholder="placeholder"></input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round class="confirmBtn" @click="submit()"
        >{{btnText}}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
            :title="i18n.User.setTime"
            :visible.sync="updateDateType"
            center
            custom-class='dateBox'
    >
      <div style="text-align: center;">
        <div>
          <el-select @change='optionChange' v-model="country" :placeholder="i18n.User.Select">
            <el-option
                    v-for="item in countryArr"
                    :key="item.id"
                    :label="item.pid"
                    :value="item.name">
            </el-option>
          </el-select>
        </div>
        <div style="margin-top:14px">
          <el-select v-model="region" :placeholder="i18n.User.region">
            <el-option
                    v-for="item in regionArr"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round class="confirmBtn" @click="submitDate(title)"
        >{{btnText}}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import {
    UpdateName,
    getCountryList,
    getRegionList,
    UpdateDate,
    UpdateImgLogo,
  } from '@/api/userInfo.js'
  import * as upload from '@/utils/upload'
  import Clipboard from '@/utils/clipboard'

  export default {
    components: {},
    data() {
      return {
        updateDiaLogType: false,
        updateDateType: false,
        countryArr: [],
        loading: false,
        regionArr: [],
        country: '',
        region: '',
        value: '',
        nickName: '',
        title: '',
        placeholder: '请输入',
        btnText: '确认',
        ossUrl: '',
      }
    },
    computed: {
      ...mapState('app', {
        userInfo: (state) => state.userInfo,
      }),
      action() {
        return process.env.ENV === 'dev'
          ? 'http://localhost:8080/api/api-web/user/edit-img-logo'
          : 'https://test.myfeifan.com.cn/api-web/user/edit-img-logo'
      },
      i18n() {
        return this.$t('messages')
      },
    },
    mounted() {
      this.getCountryList()
    },
    methods: {
      ...mapActions('app', ['GetUserInfo']),
      getCountryList() {
        this.loading = true
        getCountryList().then((res) => {
          if (res.code == 200) {
            this.countryArr = res.data
            this.loading = false
          }
        })
      },
      uploadRequest(param) {
        upload.file({
          file: param.file,
          fileName: param.file.name,
          onSuccess: ({data}) => {
            UpdateImgLogo({oss_img_logo: data.oss_url}).then((res) => {
              if (res.code == 200) {
                this.$refs.upload.clearFiles()
                this.GetUserInfo()
              }
            })
          },
        })
      },
      optionChange() {
        const ids = this.countryArr.find((item) => item.name == this.country)
        getRegionList({pid: ids.id}).then((res) => {
          if (res.code == 200) {
            this.regionArr = res.data
          }
        })
      },

      prev() {
        this.$router.push({name: 'home'})
      },
      updateData(v) {
        if (v == 'nickName') {
          this.title = '修改昵称'
          this.placeholder = '请输入新昵称'
        }
        if (v == 'email') {
          this.title = '验证邮箱'
          this.placeholder = '请输入邮箱验证码'
        }
        if (v == 'bindEmail') {
          this.title = '绑定邮箱'
          this.placeholder = '请输入邮箱地址'
          this.btnText = '发送验证邮件'
        }
        if (v == 'relieveEmail') {
          this.title = '验证码已发送至绑定邮箱'
          this.placeholder = '请输入验证码'
        }

        this.updateDiaLogType = true
      },
      copyStudentNo() {
        Clipboard(this.userInfo.student_no)
        this.$message.success(this.$t('User.copyTip'))
      },
      updateDate() {
        this.updateDateType = true
      },
      submitDate() {
        UpdateDate({country_zone: this.region}).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.updateDateType = false
            this.GetUserInfo()
          }
        })
      },
      submit() {
        UpdateName({name: this.value})
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.updateDiaLogType = false
              this.GetUserInfo()
            }
          })
          .catch((err) => {
            this.$message.success(err)
            this.updateDiaLogType = false
          })
      },
      updatePhoneNumber() {
        this.$router.push({name: 'updatePhoneNumber'})
      },
      updatePsw() {
        this.$router.push({name: 'updatePsw'})
      },
    },
  }
</script>

<style lang="less" scoped>
  svg.icon {
    position: absolute;
    top: 6px;
  }

  .box {
    .banerText {
      margin-top: 26px;
      display: flex;
      font-size: 14px;
      line-height: 17px;
      color: #1f1f40;

      .homeText {
        color: #9c9c9c;
        font-size: 14px;
      }

      .icon {
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    .content {
      margin-top: 19px;

      .header {
        height: 67px;
        border-bottom: 1px solid #dcdcdc;
        line-height: 67px;
        font-size: 16px;

        font-weight: bold;
        color: #1f1f40;
      }

      .box {
        .left {
          padding-top: 70px;

          .circleImg {
            border-radius: 50px;
            width: 100px;
            height: 100px;
          }
        }

        .right {
          font-size: 14px;

          font-weight: 400;
          line-height: 55px;
          color: #3a3a3a;

          .btn {
            color: #3b86ff;
          }
        }
      }
    }

    .confirmBtn {
      background: #3b86ff;
      color: #ffffff;
      width: 223px;
      height: 42px;
    }

    input {
      border: none;
      width: 195px;
      border-bottom: 1px solid #dcdcdc;
      height: 20px;
      line-height: 17px;
    }

    /deep/ .el-dialog__title {
      font-size: 14px;

      font-weight: bold;
      line-height: 40px;
      color: #1f1f40;
    }

    /deep/ .el-dialog.el-dialog--center {
      width: 369px;
      height: 224px;
      border-radius: 10px;
    }

    /deep/ .el-dialog.el-dialog--center.dateBox {
      width: 474px !important;
      height: 288px !important;
    }

    /deep/ .el-select {
      width: 315px;
    }

    /deep/ .el-card.box-card.is-always-shadow {
      box-sizing: border-box;
    }

    /deep/ .el-card__body {
      padding: 0 48px 48px;
    }

    /deep/ .el-button--primary {
      color: #1f1f40;
      background-color: #ededed;
      border-color: #ededed;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
</style>
